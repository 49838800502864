@import "bootstrap";
@import "css/variables.scss";
@import "./css/bootstrap-overrides_v1.scss";
@import "./css/checkingin.scss";

.content {
  transition: 300ms;
  margin-left: 10rem;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $background;
}
section {
  padding-top: 20px;
}
.overlay {
  background: $white;
  color: $dark-green;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 5000;
  top: 0;
  left: 0;
  float: left;
  text-align: center;
  padding-top: 20%;
  opacity: 0.7;
}

.card {
  min-height: 80vh;
}

.card-body {
  margin-bottom: 30px;
}

@media screen and (max-width: 500px) {
  .content {
    margin-left: 0;
  }
}
