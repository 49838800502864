@import "../../css/report-general-styles.scss";

.summary {
  .report-content {
    .introduction {
      h3 {
        color: $green;
      }
    }
    .actions {
      font-weight: 900;
      .row {
        justify-content: space-around;
      }
      text-align: center;
      .total-actions {
        margin: 2rem auto;
        color: $green;
        h1 {
          font-size: 4rem;
          font-weight: 900;
        }
        h2 {
          font-size: 2rem;
          font-weight: 900;
        }
      }
      .check-ins,
      .mindfulness,
      .reflections {
        color: $green;
        background-color: $lightGreen;
        padding: 2rem 2.75rem;
        border-radius: 1.5rem;
        h3 {
          margin-top: 0.5rem;
          margin-bottom: 0;
          font-size: 1.5rem;
          font-weight: bold;
        }
        span {
          font-size: 3rem;
        }
      }
      .mindfulness {
        color: $red;
        background-color: $lightRed;
      }
      .reflections {
        color: $darkPink;
        background-color: $lightPink;
      }
    }
    .graphics {
      padding: 6rem 0 0;
      .row {
        .col-half {
          text-align: center;
          &:first-child {
            h3 {
              color: $red;
              font-weight: bold;
              font-size: 1.5rem;
            }
          }
          &:last-child{
            h3{
              color: $green;
              font-weight: bold;
              font-size: 1.5rem;
            }
          }
        }
      }
    }
  }
}
