@import "../../css/report-general-styles.scss";

article {
  &.well-being-index {
    .report-content {
      padding-top: 2rem;
      h3 {
        color: $green;
        font-weight: bold;
        margin-bottom: 0;
      }
      .overview {
        padding-top: 3rem;
      }
      .challenge-dates {
        h3 {
          text-align: center;
        }
      }
    }

    .bottom {
      background-color: $lightGrey;
      padding: 3rem;
      padding-right: 4rem;
      padding-bottom: 6rem;
      h3 {
        color: $green;
        font-weight: bold;
      }
      img {
        width: 100%;
      }
      .col-half {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        .index-reflection {
          padding: 1rem;
          p {
            &:last-child {
              font-family: $secondaryFont;
              font-style: italic;
              color: $green;
              span {
                font-weight: bold;
              }
            }
          }
        }
      }
    }
  }
}
