@import "../../css/report-general-styles.scss";

article {
  &.congratulations {
    .report-content {
      img {
        display: block;
        margin: 0 auto;
        margin-bottom: 4rem;
      }
      padding: 4rem;
      padding-top: 8rem;
      .signature {
        margin: 4rem auto;
        p {
          &.ceo-signature {
            font-family: $handwritingFont;
            font-size: 1.5rem;
          }
        }
      }
    }
  }
}
