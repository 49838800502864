a {
  color: $medium-green;
  @include transition(all, 0.25s, ease-in-out);
  &:hover {
    color: $bright-green;
    text-decoration: none;
  }
}

ul {
  list-style: none;
  padding-left: 0;
}

.btn-consideration {
  width: 220px !important;
  height: 220px !important;
  font-size: 9em !important;
  margin: auto;
}

.btn-cancel {
  color: $white;
  background-color: $light-medium-grey;

  &:hover {
    color: $white;
    background-color: $medium-grey;
  }
}

.btn-delete {
  color: $white;
  background-color: $orange;
  &:hover {
    color: $white;
    background-color: $light-orange;
  }
}

.sexy_line {
  margin: 25px 50px;
  height: 2px;
  background: $bright-green;
  background: -webkit-gradient(
    linear,
    0 0,
    100% 0,
    from(white),
    to(white),
    color-stop(50%, $bright-green)
  );
}

@media screen and (max-width: 500px) {
  .card-header {
    p {
      &.long-title-header {
        padding-left: 1.5em;
      }
    }
  }

  .next-link {
    padding-top: 1em;
  }
}
