@import "../../css/report-general-styles.scss";

article{
	&.meditations-reflections {
		.chart-title{
			text-align: center;
			font-weight: bold;
			font-size: 1.1rem;
		}
		.report-content {
		  .introduction {
			h3 {
			  color: $green;
			  margin-top: 0;
			  font-weight: bold;
			}
			margin: 2rem auto;
			img {
			  width: 100%;
			}
			.row {
			  .col-half {
				&:first-child {
				  p {
					&:last-child {
					  font-family: $secondaryFont;
					  color: $green;
					  font-style: italic;
					}
					span {
					  font-weight: bold;
					}
				  }
				}
			  }
			}
		  }
		}
	  
		.bottom {
		  background-color: $lightGrey;
		  padding: 4rem 3rem;
		  height: 100vh;
		  h3 {
			color: $green;
			font-weight: bold;
			margin-top: 0;
		  }
		  img{
			width: 100%;
		  }
		  .row {
			.col-half {
			  &:last-child {
				p {
				  &:last-child {
					font-family: $secondaryFont;
					font-style: italic;
					color: $green;
					span {
					  font-weight: bold;
					}
				  }
				}
			  }
			}
		  }
		}
	  }

}

