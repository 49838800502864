@import "../../css/report-general-styles.scss";

article {
  &.cover {
    background-image: url("../../assets/hero.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    .header {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      margin-top: 15%;
      text-transform: uppercase;
      background-color: transparent;
      h1 {
        font-size: 4rem;
        margin-bottom: 1rem;
        font-weight: bold;
        color: $green;
      }
      h2 {
        letter-spacing: 2px;
        font-weight: bold;
        color: $green;
      }
      span {
        &.header-separator {
          color: $yellow;
          font-weight: 400;
        }
        &.header-date {
          font-weight: 400;
        }
      }
    }
    .footer {
      display: flex;
      height: 150px;
      background-color: $green;
      bottom: 0;
      border-radius: $borderRadiusFooter;
      justify-content: center;
      position: absolute;
      width: 100%;
      align-items: center;

      img {
        margin-top: 1rem;
      }
    }
  }
}
