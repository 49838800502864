@import "../../css/report-general-styles.scss";

article {
  &.references {
    .report-content {
      padding-top: 2rem;
      p {
        margin-left: 2em;
        text-indent: -2em;
        line-height: 1.2rem;
        span {
          font-style: italic;
        }
      }
    }
  }
}
