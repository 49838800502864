@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,600;1,400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Damion&display=swap");

/* Colors */
$green: #428c94;
$lightGreen: #e9eff0;
$lightRed: #fbdad8;
$lightPink: #f1dbe7;
$yellow: #edbe1c;
$purple: #626290;
$darkPink: #b94e8b;
$brightGreen: #82c55c;
$orange: #f37223;
$red: #ef493e;
$darkGrey: #2f2e2e;
$lightGrey: #fafafa;
$mediumGrey: #555555;
$grey: #d3d3d3;
$white: #ffffff;
$black: #212121;

/* Common Styles*/
$borderRadiusHeader: 0 0 25px 25px;
$borderRadiusFooter: 25px 25px 0 0;

/* Fonts */
$primaryFont: "Lato", sans-serif;
$secondaryFont: "Lora", serif;
$handwritingFont: "Damion", cursive;

/* Globals */
.report {
  .card {
    min-height: unset;
    height: 100%;
  }
  background-color: $white;
  a {
    cursor: pointer;
    text-decoration: none;
  }

  ul {
    list-style: none;
    padding: 0;
  }

  .container {
    width: 100%;
    margin: 0 auto;
  }

  line {
    fill: "blue";
  }

  main {
    max-width: 800px;
    margin: auto;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    font-family: $primaryFont;
    line-height: 1.3rem;
    color: $mediumGrey;
  }

  .report-content {
    padding: 2rem 4rem;
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  h1 {
    font-size: 3rem;
  }
  h2 {
    font-size: 2rem;
  }
  h3 {
    font-size: 1.35rem;
  }
  h4 {
    font-size: 1.2rem;
  }
  p {
    font-size: 1rem;
  }

  .french {
    h1 {
      font-size: 2.75rem;
    }
    h2 {
      font-size: 1.75rem;
    }
    h3 {
      font-size: 1.10rem;
    }
    h4 {
      font-size: 0.95rem;
    }
    p {
      font-size: 0.8125rem;
    }
  }

  /* Page container to set page breaks */
  section {
    padding: 0;
  }
  .page {
    // height: auto;
    height: 1122px;
    position: relative;
    overflow: hidden;
    page-break-after: always;
  }

  /* Utilities */
  .text-center {
    text-align: center;
  }

  .col-half {
    flex: 0 0 calc(50% - 2rem);
    width: calc(50% - 2rem);
  }

  .reflection {
    p {
      font-family: $secondaryFont;
      font-style: italic;
    }
  }

  @media print {
    p {
      page-break-inside: avoid;
    }

    h1 {
      page-break-before: always;
    }

    .footer {
      page-break-after: always;
    }
  }
}
