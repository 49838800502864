@import "../../css/report-general-styles.scss";

.header {
  height: 100px;
  top: 0;
  border-radius: $borderRadiusHeader;
  display: flex;
  align-items: center;
  justify-content: center;
  h1 {
    color: $white;
    font-weight: bold;
  }
}
