@import "../../css/report-general-styles.scss";

article {
  &.life-happiness-meaning {
    .report-content {
      padding-bottom: 2rem;
      h3 {
        color: $green;
        font-weight: bold;
      }
      .overview {
        margin-top: 6rem;
      }
      .challenge-dates {
        h3 {
          text-align: center;
        }
      }

    }
    
    .french {
      padding-bottom: 1rem;
    }
    
    .bottom {
      position: relative;
      bottom: 1rem;
      h3 {
        color: $green;
        font-weight: bold;
      }
      .col-half {
        padding: 3rem 4rem;
        &:first-child {
          padding-right: 0;
        }
        &:last-child {
          color: $green;
          padding-left: 3rem;
          background-color: $lightGreen;
          border-top-left-radius: 3rem;
          height: 100vh;
        }
      }
    }
  }
}
