@import "../../css/report-general-styles.scss";

.footer {
	display: flex;
	height: 45px;
	background-color: $green;
	bottom: 0;
	border-radius: $borderRadiusFooter;
	justify-content: center;
	position: absolute;
	width: 100%;
	color: $white;
	align-items: center;

	img {
	  max-height: 70%;
	  max-width: 70%;
	  width: auto;
	  height: auto;
	  position: absolute;
	  top: 10px;
	  margin: 0 auto;
	}
	span {
	  font-weight: bold;
	  &.pageNumber {
		margin-left: auto;
		margin-right: 1.75rem;
		color: $yellow;
	  }
	  &.footer-link {
		margin-left: 1.75rem;
	  }
	}
  }