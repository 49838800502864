@import "../../css/report-general-styles.scss";

article {
  &.table-of-contents {
    .report-content {
      padding: 4rem;
      line-height: 1.6rem;
      .index-wrapper {
        width: 50%;
        margin: 3rem auto;
        h3 {
          color: $green;
          font-weight: bold;
          margin-bottom: 1rem;
        }
        .row {
          display: table;
          .title,
          .dots,
          .number {
            display: table-cell;
          }
          .title,
          .number {
            white-space: nowrap;
            margin: 0 3px;
          }
          .title {
            padding-right: 4px;
          }
          .number {
            padding-left: 4px;
            color: $yellow;
            font-weight: bold;
          }
          .dots {
            border-bottom: 2px dotted $mediumGrey;
            width: 100%;
            position: relative;
            bottom: 8px;
          }
        }
      }
    }
  }
}
