@import "../../css/report-general-styles.scss";

article {
  &.energy-level {
    .report-content {
      padding: 4rem;
      .introduction {
        h3 {
          color: $green;
          margin-top: 0;
          font-weight: bold;
        }
        .row {
          &:first-child {
            p {
              &:last-child {
                font-family: $secondaryFont;
                color: $green;
                font-style: italic;
              }
              span {
                font-weight: bold;
              }
            }
          }
          .avg-daily {
            font-weight: bold;
            span {
              color: $green;
            }
          }
        }
      }
    }

    .bottom {
      background-color: $lightGrey;
      padding: 3rem;
      height: 100vh;
      h3 {
        color: $green;
        font-weight: bold;
        margin-top: 0;
      }
      img {
        width: 100%;
      }
      .row {
        .col-half {
          &:last-child {
            p {
              &:last-child {
                font-family: $secondaryFont;
                font-style: italic;
                color: $green;
                span {
                  font-weight: bold;
                }
              }
            }
          }
        }
      }
    }
  }
}
