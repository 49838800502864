/* Colors */
$orange: #ea6944;
$bright-orange: #efefef;
$light-orange: #f59088;
$light-orange-second: #f58768;
$yellow: #f0bf03;
$darker-green: #204040;
$dark-green: #3c7778;
$medium-green: #418384;
$bright-green: #51a3a4;
$highlight: #2b5656;
$white: #ffffff;
$background: #f2f2f2;
$light-grey-box-shadow-button: #dddddd;
$light-grey-box-shadow-card: #fafafa;
$light-grey-background: #eeeeee;
$light-grey-slider: #d3d3d3;
$light-grey-display-number: #cfcfc5;
$light-grey-border-input: #ced4da;
$light-medium-grey: #888888;
$light-medium-grey-card: #f7f9f9;
$grey-label-text: #979797;
$medium-grey: #636463;
$black: #000000;
$border: #ecebeb;

/* Fonts */
$light: 300;
$semibold: 500;
$bold: 700;
$extra-bold: 900;

/* Mixins */
@mixin transition($what: all, $time: 0.2s, $how: ease-in-out) {
  -webkit-transition: $what $time $how;
  -moz-transition: $what $time $how;
  -ms-transition: $what $time $how;
  -o-transition: $what $time $how;
  transition: $what $time $how;
}
