@import "../../css/report-general-styles.scss";

article {
  &.going-forward {
    .report-content {
      padding-top: 2rem;
      .prompts {
        width: 70%;
        margin: 3rem auto;
        background-color: $lightGreen;
        padding: 3rem;
        color: $green;
        border-radius: 4rem;
        p {
          font-family: $secondaryFont;
          font-style: italic;
        }
		h3{
			font-weight: bold;
		}
      }
      .lines-wrapper {
        margin: 2rem auto 2rem;
        .row {
          .line {
            border-bottom: 1px solid $grey;
            width: 100%;
            padding: 0.9rem 0;
            &:first-child {
              padding: 0;
            }
          }
        }
      }
    }
  }
}
